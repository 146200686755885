




















import { Component, Vue } from 'vue-property-decorator';
@Component({})
export default class EmailRegisterLayout extends Vue {
  
  get template() {
    return this.$store.state.template;
  }
  
  removeTemplateFromStore() {
    this.$store.commit('set_template', null);
  }
}
