

































































































































































































import {Component, Mixins, Watch, Vue} from 'vue-property-decorator';
import RegisterLayout from '@/components/auth/register/RegisterLayout.vue';
import EmailRegisterLayout from '@/components/auth/register/EmailRegisterLayout.vue';
import reduce from 'lodash/reduce';
import SocialAuth from "@/components/auth/SocialAuth.vue";
import PusherService from '@/PusherService';
import {ValidationErrorParserMixin} from "@/mixins/ValidationErrorParserMixin";
import UserService from "@/services/UserService";
import { UpdateUserAccountType, VerifyEmailWithPin } from '@/graphql/auth';
import RegistrationSteps from '../RegistrationSteps.vue';

@Component({
  components: {SocialAuth, RegisterLayout, RegistrationSteps, EmailRegisterLayout},
})
export default class Register extends Mixins(ValidationErrorParserMixin) {
  clicked = false;
  resendClicked = false;
  email: string | null = null;
  error: string | null = null;
  first_name: string | null = null;
  last_name: string | null = null;
  password: string | null = null;
  toc_view_active = false;
  toc_accepted = false;
  acceptTerms = true;
  verification: Array<string | null> = [null, null, null, null, null, null];
  service!: UserService | null;
  accountDetailSteps: number = 0;
  referralCode: string | null = null;
  currentIpAddress: string | null = null;
  userAccountType: string | null = null;
  isLoading: boolean = false;
  nameWorkspace: boolean = false;
  workspaceName: string | null = null;
  showSkipButton: boolean = false;
  accountCreated: boolean = false;
  showEmailInput: boolean = false;

  public $refs!: {
    p0: HTMLFormElement;
    p1: HTMLFormElement;
    p2: HTMLFormElement;
    p3: HTMLFormElement;
    p4: HTMLFormElement;
    p5: HTMLFormElement;
  };

  created() {
    let token = this.$route.query.token;
    if (token) {
      this.$store.commit('logged_in', token);
    }
    this.showSkipButton = false;
  }

  mounted() {
    this.$gtag.event('pmb_app_email_registration_mounted');

    this.service = new UserService(this.$apollo);

    if (this.$store.state.currently_on_verification_code_step_email) {
      this.accountDetailSteps = 3;
    }

  

    this.$nextTick(function () {
      //@ts-ignore ToDo: fix type issue
      this.$refs.emailInput.focus();
    });

    if (!this.$store.state.token && this.$route.query.email) {
      //@ts-ignore
      this.getAccessTokenWithEmail(this.$route.query.email);
    }

    if(this.$route.query.referral) {
      //@ts-ignore
      this.referralCode = this.$route.query.referral;
    }

    //@ts-ignore
    this.$ipaddress.getIpAddress().then((res: string)=>{
      this.currentIpAddress = res;
    });
  }

  get allInputsAdded(){
    if(this.password && this.first_name && this.last_name && this.email){
      return true;
    } else {
      return false;
    }
  }

  get isEmailValid() {
    const email = this.email ?? ''; 
    return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email.trim());
  }

  get verificationPin() {
    return reduce(
      this.verification,
      function (sum, n) {
        if (n !== null && n != "-") {
          return sum + n;
        }
        return sum;
      },
      ''
    );
  }

  get isPasswordValid() {
    const password = this.password ?? '';
    return /^(?=.*[A-Z])(?=.*\d).{8,}$/.test(password);
  }

  get pinOk() {
    return this.verificationPin.length == 6;
  }

  get me() {
    return this.$store.state.me;
  }

  get workspaceDemoName() {
    if(this.workspaceName) {
      return this.workspaceName;
    } else {
      //return 'Space-'+(Math.random() * 100);
      return 'Work';
    }
  }

  get domainName() {
    return this.workspaceDemoName;
  }

  nextAccountStep() {
    if(this.accountDetailSteps === null) {
      this.accountDetailSteps = 0;
    } else if(this.accountDetailSteps >= 0) {
      if(this.accountDetailSteps === 0) {
        this.service?.checkEmail(this.email).then((res:any) => {
        
          if(res.data.checkEmail) {
            
            this.accountDetailSteps!++;
            this.validation = null;
          }else{
            this.accountDetailSteps = 0;
            //@ts-ignore
            this.validation = {'input.email': 'Email already exists'};
            return;
          }
        })
      }
      if(this.accountDetailSteps >= 1) {
        this.accountDetailSteps!++;
      }
    }
    /*
    if(!this.accountDetailSteps) {
      this.accountDetailSteps = 0;
    } else {
      this.accountDetailSteps++;
    }

     */


    if(this.accountDetailSteps === 1) {
      this.$nextTick(function () {
        //@ts-ignore ToDo: fix type issue
        this.$refs.fNameInput.focus();
      });
    }
  }

  handleShowInput() {
    this.showEmailInput = true
    this.$nextTick(function () {
      //@ts-ignore ToDo: fix type issue
      this.$refs.emailInput.focus();
    });
  }

  acceptEmailMarketing() {
    this.service?.acceptEmailMarketing(this.acceptTerms)
  }

  confirm() {
    this.clicked = true;

    this.$apollo.mutate({
      mutation: VerifyEmailWithPin,
      variables: {
        pin: this.verificationPin,
      },
        
    }).then((_) => {
      this.$gtag.event('pmb_app_decision_email');
      this.$router.push({name: 'registration-steps' });
     
    }).catch((error) => {
      this.parseValidationError(error)
      this.$gtag.event('pmb_app_decision_email_verification_failed');
      this.resetAndFocus();
    }).finally(() => {
      //@ts-ignore
      //this.$refs.fNameInput.focus();
      this.clicked = false;
    }); 
  }

  register() {
    if (!this.email) {
      return;
    }
    if(this.first_name === ' '){
      //@ts-ignore
      this.validation = {'input.first_name': 'Please give first name'};
      return;
    }

    if(this.last_name === ' '){
      //@ts-ignore
      this.validation = {'input.last_name': 'Please give last name'};
      return;
    }

    if(!this.acceptTerms) {
      return;
    }

    this.validation = null
    this.clicked = true;
    this.isLoading = true;
    this.service?.register(this.email, this.first_name, this.last_name, this.currentIpAddress).then(({data: {register}}) => {
      this.$gtag.event('sign_up', {method: 'Google'})
      this.$store.commit('logged_in', register.access_token);

      // this.accountCreated = true;
      PusherService.pusherAuth();
      this.acceptEmailMarketing();

      this.$store.commit('set_currently_on_verification_code_step_email', false);
      this.$gtag.event('pmb_app_decision_email_account_creation_success');
      this.$nextTick(function () {
        //@ts-ignore ToDo: fix type issue
        this.$refs.p0.focus();
      });
    }).catch((error) => {
      this.parseValidationError(error)
    }).finally(() => {
      this.clicked = false;
      this.isLoading = false;
      this.skipVerification();
      this.$gtag.event('pmb_app_decision_email');
      this.$router.push({name: 'registration-steps' });
    });
  }

  resendCode(): void {
    if (this.resendClicked) {
      return;
    }

    this.service?.resendEmailVerification().then(() => {
      this.resendClicked = true;
    }).catch(() => {
      this.$gtag.exception({
        'description': 'Registration code was not received',
        'fatal': false
      })
    })
  }

  resetAndFocus() {
    this.verification = [null, null, null, null, null, null];
    this.$nextTick(() => {
      this.$refs.p0.focus();
    });
  }

  getAccessTokenWithEmail(email: string) {
    this.service?.accessTokenWithEmail(email).then((data: any) => {
    
      this.$gtag.event('sign_up', {method: 'Google'})

      this.$store.commit('logged_in', data.data.getAccessTokenWithEmail.access_token);

      PusherService.pusherAuth();
    });
  }

  updateUserAccountType(type: string) {
    this.userAccountType = type;
    this.isLoading = false;
    this.$gtag.event('pmb_app_onboarding_user_type_' + type);
    this.$apollo
      .mutate({
        mutation: UpdateUserAccountType,
        variables: {
          account_type: this.userAccountType
        },
      })
      .then((_data: any) => {
       
        // this.userType = false;
        // this.nameWorkspace = true;
        this.$gtag.event('pmb_app_onboarding_account_type_selected');

        this.$store.commit('set_currently_on_verification_code_step_email', false);

        this.showSkipButton = false;

        this.$router.push({ name: "workspace-create", query: { "account-type": this.userAccountType} });
      })
      .catch((e: any) => {
        Vue.prototype.$consoleLog(e)
      })

  }

  verificationSkipAfterDelay() {
    setTimeout(() => {
      this.showSkipButton = true;
    }, 15000); 
  }

  skipVerification() {
    this.$store.commit('set_currently_on_verification_code_step_email', false);
    this.showSkipButton = false;
  }

  @Watch('verification')
  watchVerificationCode(newValue: string[]) {
    if (this.verificationPin && this.verificationPin.length > 6) {
      let verify = this.verificationPin;
      this.$nextTick(() => {
        verify = verify.replace('-', '');
        if (verify.length > 6) {
          verify = verify.slice(0, -1)
        }
        this.verification = [...verify];
      })
    }
    const isComplete = newValue.every(v => v !== null && v !== '');
    if (isComplete) {
      this.confirm();
    }
  }

  @Watch('accountDetailSteps')
  watchVerificationSteps() {
    if(this.accountDetailSteps === 3) {
      this.verificationSkipAfterDelay();
    }
  }
}
  

